









































































import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

import Vue from "vue";
extend("required", required);

export default Vue.extend({
  props: ["classId"],
  data() {
    return {
      reason: {
        cancellation_type: "",
        cancellation_reason: ""
      }
    };
  },
  computed: {
    invalidForm(): boolean {
      return this.reason.cancellation_type === "";
    }
  },
  methods: {
    async saveReason(userAction: boolean): Promise<void> {
      if (userAction) {
        try {
          const postData = {
            courseId: this.$route.params.id,
            classId: this.classId,
            reason: this.reason
          };
          await this.$store.dispatch("cancelClass", postData);
          this.$emit("hideReason", userAction);
          this.$store.dispatch("showNotification", {
            message: "Class cancelled successfully"
          });
          this.$emit("reload", {});
        } catch (err) {
          this.$emit("error", {});
          this.$emit("hideReason", userAction);
        }
      }
    },
    hideReason(userAction: boolean): void {
      this.$emit("hideReason", userAction);
    }
  }
});
